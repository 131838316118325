.fantasia{
	margin: 10px;
	background:rgb(243, 243, 243);
	text-align: center;
	font-size: 2.5em;
	/* margin: 30 auto; */
	border: 1.6px solid rgb(190, 190, 190);
	/* width: "100%"; */
}

.space3{
	font-size: 0.73em;
	margin: 3 auto;
}

.space4{
	font-size: 0.54em;
	margin: 3 auto;
}

.space2button{
	font-size: 0.95em;
	margin: 3.5 auto;
}

.created3space{
	font-size: 0.73em;
	margin: 3 auto;
}

.adress{
	font-size: 0.657em;
	margin: 3 auto;
}

.space4button{
	font-size: 0.815em;
	display:flex;
	align-items: flex-start;
}

.company_map {
	height: 400px;
}

.row{
	display:flex;
	align-items: flex-start;
}

.title_info4{
	color: #007399;
	margin-left: 1%;
	font-size: 20px;
}
